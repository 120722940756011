import _extends from "@babel/runtime/helpers/esm/extends";
import buildClickCommonEvent from '../utils/buildClickCommonEvent';
var buildBuyBoxImpression = function buildBuyBoxImpression(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var product = _ref.product,
    structure = _ref.structure;
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:buy-box',
    eventLabel: 'veja-mais-ofertas-para-este-produto',
    eventNonInteraction: '0'
  }, buildClickCommonEvent(_extends({
    accountId: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id
  }, product), false));
};
var buildBuyBoxButtonImpression = function buildBuyBoxButtonImpression(_ref2) {
  var _structure$cookies2, _structure$cookies2$a;
  var product = _ref2.product,
    structure = _ref2.structure;
  return _extends({
    eventAction: 'add-to-cart:click',
    eventCategory: 'produto:geral',
    eventLabel: 'escolha-a-melhor-oferta',
    eventNonInteraction: '0'
  }, buildClickCommonEvent(_extends({
    accountId: structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : (_structure$cookies2$a = _structure$cookies2.accountData) == null ? void 0 : _structure$cookies2$a.id
  }, product)));
};
export var buyBoxButtonClick = function buyBoxButtonClick(_ref3) {
  var product = _ref3.product;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildBuyBoxButtonImpression(_extends({
    product: product
  }, args));
};
export var buyBoxSeeMoreOffers = function buyBoxSeeMoreOffers(_ref4) {
  var product = _ref4.product;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildBuyBoxImpression(_extends({
    product: product
  }, args));
};