import { isInternationalPrice, magaluCompanies, slug } from '@magalu/mixer-utils';
import { wishlistItem } from './wishlistItem';
import { getNominalDiscountValue, getPercentualDiscountValue } from './discountCalc';
var buildDimension7 = function buildDimension7(priceData) {
  var price = (priceData == null ? void 0 : priceData.price) || null;
  var bestPrice = (priceData == null ? void 0 : priceData.bestPrice) || null;
  var fullPrice = (priceData == null ? void 0 : priceData.fullPrice) || null;
  return "preco-de:".concat(price !== null ? price : 'null', "|pix:").concat(bestPrice !== null ? bestPrice : 'null', "|mpay:null|boleto:").concat(fullPrice !== null ? fullPrice : 'null');
};
var buildDimension11 = function buildDimension11(data) {
  var _data$product;
  return (data == null ? void 0 : data.available) || (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.available) ? 'verdadeiro' : 'falso:indisponivel-cep';
};
var buildDimension23 = function buildDimension23(seller, togglePdpSellerScore) {
  var _seller$details, _seller$details$score;
  return seller.category === '1p' || !!magaluCompanies[seller.id] ? '0' : "".concat(Number(!!togglePdpSellerScore) || '1', ":").concat(((_seller$details = seller.details) == null ? void 0 : (_seller$details$score = _seller$details.score) == null ? void 0 : _seller$details$score.toString()) || null);
};
var buildDimension41 = function buildDimension41(offerTags) {
  return "etiqueta:".concat((offerTags == null ? void 0 : offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0');
};
var buildDimension103 = function buildDimension103(data) {
  var _data$ads, _data$ads2, _data$ads3;
  return (data == null ? void 0 : (_data$ads = data.ads) == null ? void 0 : _data$ads.id) ? "ads:".concat(data == null ? void 0 : (_data$ads2 = data.ads) == null ? void 0 : _data$ads2.id, "|campaignId:").concat(data == null ? void 0 : (_data$ads3 = data.ads) == null ? void 0 : _data$ads3.campaignId) : (data == null ? void 0 : data.origin) || 'null';
};
var buildDimension112 = function buildDimension112(shippingTag) {
  if (!shippingTag) return null;
  var cost = (shippingTag == null ? void 0 : shippingTag.cost) === 0 ? 0 : shippingTag == null ? void 0 : shippingTag.cost;
  var timeSlug = slug((shippingTag == null ? void 0 : shippingTag.time) || '');
  var complementSlug = slug((shippingTag == null ? void 0 : shippingTag.complement) || '');
  var sourceValue = shippingTag == null ? void 0 : shippingTag.source;
  var deliveryString = "entrega-padr\xE3o:".concat(timeSlug || null, ":").concat(cost || null, "|retira-loja:").concat(complementSlug || null, ":").concat(sourceValue || null);
  return deliveryString.slice(0, 99) || null;
};
var buildDimension87 = function buildDimension87(data) {
  var _data$offerTags, _data$offerTags$;
  return [data == null ? void 0 : (_data$offerTags = data.offerTags) == null ? void 0 : (_data$offerTags$ = _data$offerTags[0]) == null ? void 0 : _data$offerTags$.replace(/_/g, '-'), isInternationalPrice(data) && 'compra-internacional'].filter(Boolean).join('|') || null;
};
export var selectItemDimensions = function selectItemDimensions(data) {
  var _data$seller, _data$installment$tot, _data$installment, _data$installment$qua, _data$installment2, _data$installment$amo, _data$installment3, _data$rating, _data$rating$count, _data$rating2, _data$rating2$score, _data$seller2, _data$seller3, _data$attributes, _data$attributes$, _data$attributes$$val, _data$attributes2, _data$attributes2$, _data$attributes2$$va, _data$badges, _data$badges$;
  return {
    dimension5: (data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category) || null,
    dimension7: buildDimension7(data == null ? void 0 : data.price),
    dimension8: "cartao-luiza:null:null:null|cartao-credito:".concat((_data$installment$tot = data == null ? void 0 : (_data$installment = data.installment) == null ? void 0 : _data$installment.totalAmount) !== null && _data$installment$tot !== void 0 ? _data$installment$tot : null, ":").concat((_data$installment$qua = data == null ? void 0 : (_data$installment2 = data.installment) == null ? void 0 : _data$installment2.quantity) !== null && _data$installment$qua !== void 0 ? _data$installment$qua : null, ":").concat((_data$installment$amo = data == null ? void 0 : (_data$installment3 = data.installment) == null ? void 0 : _data$installment3.amount) !== null && _data$installment$amo !== void 0 ? _data$installment$amo : null),
    dimension11: buildDimension11(data),
    dimension23: buildDimension23(data == null ? void 0 : data.seller, data == null ? void 0 : data.togglePdpSellerScore),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : (_data$rating$count = _data$rating.count) == null ? void 0 : _data$rating$count.toString(), "|av:").concat(data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : (_data$rating2$score = _data$rating2.score) == null ? void 0 : _data$rating2$score.toString()),
    dimension41: buildDimension41(data == null ? void 0 : data.offerTags),
    dimension61: (data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id) || null,
    dimension62: (data == null ? void 0 : (_data$seller3 = data.seller) == null ? void 0 : _data$seller3.deliveryId) || null,
    dimension74: "".concat(data == null ? void 0 : (_data$attributes = data.attributes) == null ? void 0 : (_data$attributes$ = _data$attributes[0]) == null ? void 0 : (_data$attributes$$val = _data$attributes$.values) == null ? void 0 : _data$attributes$$val.length, ":").concat(data == null ? void 0 : (_data$attributes2 = data.attributes) == null ? void 0 : (_data$attributes2$ = _data$attributes2[0]) == null ? void 0 : (_data$attributes2$$va = _data$attributes2$.values) == null ? void 0 : _data$attributes2$$va.toString()),
    dimension77: "nominal:".concat(getNominalDiscountValue(data == null ? void 0 : data.installment, data == null ? void 0 : data.price), "|percentual:").concat(getPercentualDiscountValue(data == null ? void 0 : data.price)),
    dimension80: (data == null ? void 0 : data.pageName) === 'busca' ? (data == null ? void 0 : data.term) || null : null,
    dimension86: slug(data == null ? void 0 : (_data$badges = data.badges) == null ? void 0 : (_data$badges$ = _data$badges[0]) == null ? void 0 : _data$badges$.text) || null,
    dimension87: buildDimension87(data),
    dimension103: buildDimension103(data),
    dimension106: "alg:".concat((data == null ? void 0 : data.pageName) || 'null', "-null-").concat(slug(data == null ? void 0 : data.list) || 'null', "|type:").concat((data == null ? void 0 : data.displayMode) || 'null'),
    dimension112: buildDimension112(data == null ? void 0 : data.shippingTag),
    dimension115: wishlistItem(data == null ? void 0 : data.variationId)
  };
};