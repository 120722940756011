var shippingError = function shippingError(data, _ref) {
  var _data$message, _structure$route;
  var structure = _ref.structure;
  var errorMessage = (_data$message = data == null ? void 0 : data.message) !== null && _data$message !== void 0 ? _data$message : 'cep-invalido';
  return {
    action: 'impression',
    cep: "".concat(data == null ? void 0 : data.zipCode),
    content_type: "".concat(structure == null ? void 0 : structure.name, ":frete"),
    deliveriesId: null,
    event: 'shipping_error',
    item_id: "".concat(structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.productId, ":falso:").concat(errorMessage)
  };
};
export default shippingError;