import _extends from "@babel/runtime/helpers/esm/extends";
import { itemVariantParser } from '../utils/itemVariantParser';
import { parseUrl } from '../utils/parseUrl';
import { addToCartDimensions } from '../utils/addToCartDimensions';
import { isInternationalPrice, normalize, slug } from '@magalu/mixer-utils';
var parseItemCategory = function parseItemCategory(category, subcategory) {
  var categoryId = slug((category == null ? void 0 : category.id) || 'null');
  var subcategoryId = slug((subcategory == null ? void 0 : subcategory.id) || 'null');
  return "".concat(categoryId, "/").concat(subcategoryId);
};
var getProductPrice = function getProductPrice(data) {
  var _data$product, _data$product$price, _data$product2, _data$product2$price, _data$product3, _data$product3$instal;
  var bestPrice = parseFloat(data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$price = _data$product.price) == null ? void 0 : _data$product$price.bestPrice);
  var fullPrice = parseFloat(data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$price = _data$product2.price) == null ? void 0 : _data$product2$price.fullPrice);
  var installmentTotal = parseFloat(data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$instal = _data$product3.installment) == null ? void 0 : _data$product3$instal.totalAmount);
  return (data == null ? void 0 : data.pageName) === 'buybox' ? bestPrice || fullPrice || null : installmentTotal || fullPrice || bestPrice || null;
};
var buildItemCategory5 = function buildItemCategory5(data) {
  var _data$product4, _data$product4$offerT, _data$product5, _data$product5$badges, _data$product5$badges2;
  var isInternationalProduct = isInternationalPrice(data.product);
  var offerTag = data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : (_data$product4$offerT = _data$product4.offerTags) == null ? void 0 : _data$product4$offerT[0];
  var tag = [offerTag && "tag:".concat(offerTag == null ? void 0 : offerTag.replace(/_/g, '-')), isInternationalProduct && 'tag:compra-internacional'].filter(Boolean).join('|') || 'tag:sem-tag';
  return "selo:".concat(normalize(data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : (_data$product5$badges = _data$product5.badges) == null ? void 0 : (_data$product5$badges2 = _data$product5$badges[0]) == null ? void 0 : _data$product5$badges2.text) || 'sem-selo', "|").concat(tag);
};
var parseEcommerce = function parseEcommerce(data) {
  var _data$product6, _data$product6$brand, _data$product7, _data$product8, _data$product9, _data$product9$rating, _data$product9$rating2, _data$product10, _data$product10$ratin, _data$product10$ratin2, _data$product11, _data$product$seller$, _data$product12, _data$product12$selle, _data$product$seller$2, _data$product13, _data$product13$selle, _data$product14, _data$product15, _data$product16, _data$product17;
  return {
    items: [_extends({
      item_brand: slug((data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : (_data$product6$brand = _data$product6.brand) == null ? void 0 : _data$product6$brand.label) || 'null'),
      item_category: parseItemCategory(data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : _data$product7.category, data == null ? void 0 : (_data$product8 = data.product) == null ? void 0 : _data$product8.subcategory),
      item_category3: "qtd-av:".concat((data == null ? void 0 : (_data$product9 = data.product) == null ? void 0 : (_data$product9$rating = _data$product9.rating) == null ? void 0 : (_data$product9$rating2 = _data$product9$rating.count) == null ? void 0 : _data$product9$rating2.toString()) || null, "|av:").concat((data == null ? void 0 : (_data$product10 = data.product) == null ? void 0 : (_data$product10$ratin = _data$product10.rating) == null ? void 0 : (_data$product10$ratin2 = _data$product10$ratin.score) == null ? void 0 : _data$product10$ratin2.toString()) || null, "|").concat((data == null ? void 0 : (_data$product11 = data.product) == null ? void 0 : _data$product11.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
      item_category4: "ven-por:".concat((_data$product$seller$ = data == null ? void 0 : (_data$product12 = data.product) == null ? void 0 : (_data$product12$selle = _data$product12.seller) == null ? void 0 : _data$product12$selle.id) !== null && _data$product$seller$ !== void 0 ? _data$product$seller$ : null, "|ent-por:").concat((_data$product$seller$2 = data == null ? void 0 : (_data$product13 = data.product) == null ? void 0 : (_data$product13$selle = _data$product13.seller) == null ? void 0 : _data$product13$selle.deliveryId) !== null && _data$product$seller$2 !== void 0 ? _data$product$seller$2 : null),
      item_category5: buildItemCategory5(data),
      item_id: (data == null ? void 0 : (_data$product14 = data.product) == null ? void 0 : _data$product14.variationId) || null,
      item_list_name: "".concat(slug((data == null ? void 0 : data.pageName) || 'null'), ":").concat(slug((data == null ? void 0 : (_data$product15 = data.product) == null ? void 0 : _data$product15.list) || 'null')),
      item_name: slug((data == null ? void 0 : (_data$product16 = data.product) == null ? void 0 : _data$product16.title) || 'null').replace(/[_\s]/g, '-').toLowerCase().slice(0, 99),
      item_variant: itemVariantParser(data == null ? void 0 : (_data$product17 = data.product) == null ? void 0 : _data$product17.attributes) || null,
      price: getProductPrice(data),
      quantity: 1
    }, addToCartDimensions(data))]
  };
};
var addToCart = function addToCart(newData) {
  var _newData$product, _newData$product2, _newData$product3, _data$customer_id;
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    eventId = _ref.eventId;
  var data = _extends({}, newData, {
    product: _extends({}, newData.product, {
      category: _extends({}, newData.product.category, {
        id: newData.product.category.id || parseUrl(newData == null ? void 0 : (_newData$product = newData.product) == null ? void 0 : _newData$product.url, 1)
      }),
      subcategory: _extends({}, newData.product.subcategory, {
        id: newData.product.subcategory.id || parseUrl(newData == null ? void 0 : (_newData$product2 = newData.product) == null ? void 0 : _newData$product2.url, 2)
      }),
      variationId: newData.product.variationId || parseUrl(newData == null ? void 0 : (_newData$product3 = newData.product) == null ? void 0 : _newData$product3.url, 0)
    })
  });
  var getEventLabel = function getEventLabel(label) {
    if (label == null ? void 0 : label.typeButton.includes('oneTouch')) {
      return 'comprar-agora';
    }
    if (label == null ? void 0 : label.typeButton.includes('addToBag')) {
      return 'adicionar-a-sacola';
    }
    if (label == null ? void 0 : label.typeButton.includes('pickupStore')) {
      return 'retirar-na-loja';
    }
    return null;
  };
  return _extends({
    event: 'add_to_cart',
    eventCategory: slug(data == null ? void 0 : data.pageName) || null,
    eventId: eventId,
    eventLabel: getEventLabel(data),
    idUsuario: (_data$customer_id = data == null ? void 0 : data.customer_id) !== null && _data$customer_id !== void 0 ? _data$customer_id : null,
    mundos: 'magalu',
    screenName: slug(data == null ? void 0 : data.pageName) || null
  }, parseEcommerce(data));
};
export default addToCart;