import dataLayerPush from '../utils/dataLayerPush';
import addToCart from './addToCart';
import addToWishlist from './addToWishlist';
import selectItem from './selectItem';
import viewItem from './viewItem';
import search from './search';
import share from './share';
import shippingError from './shippingError';
import viewItemList from './viewItemList';
import importTaxesInfoModalClose from './importTaxesInfoModalClose';
var events = {
  'addToWishlist:click': [dataLayerPush(addToWishlist)],
  pageview: [dataLayerPush(search)],
  'product:addToCart': [dataLayerPush(addToCart)],
  'product:details': [dataLayerPush(viewItem)],
  'product:importTaxesClose:click': [dataLayerPush(importTaxesInfoModalClose)],
  'product:share': [dataLayerPush(share)],
  'product:viewItemList': [dataLayerPush(viewItemList)],
  'productlist:selectItem:click': [dataLayerPush(selectItem)],
  'zipcodebar:response:error': [dataLayerPush(shippingError)]
};
export default events;